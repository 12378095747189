import React from 'react';
import './honeycomb';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as LoggingProvider } from '@rollbar/react';
import { ErrorBoundary } from 'react-error-boundary';
import Rollbar from 'rollbar';
import App from './App';
import './i18n';
import FhxError from './content/FhxError';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_NODE_ENV,
  enabled: ['development', 'staging', 'production'].includes(process.env.REACT_APP_NODE_ENV),
};

const root = ReactDOM.createRoot(document.getElementById('root'));
if (!root) {
  throw new Error('Failed to find root element.');
}
const rollbar = new Rollbar(rollbarConfig);

const handleError = (error, info) => {
  rollbar.info(`ErrorBoundary caught: ${error}`, { error, info });
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LoggingProvider config={rollbarConfig}>
        <ErrorBoundary
          onError={handleError}
          FallbackComponent={FhxError}
        >
          <App />
        </ErrorBoundary>
      </LoggingProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
