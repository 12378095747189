import FhxError from '../content/FhxError';
import { isSessionStorageAvailable, getSessionItem, setSessionItem } from './sessionStorage';
import { getQueryParam, removeQueryParam, setQueryParam } from './queryParam';

/**
 * Handles lazy loading of components with retry logic using sessionStorage and URL parameters.
 * @param {Function} componentImport - The function to import the component.
 * @param {string} name - The name of the component for logging and tracking.
 * @param {Object} logger - The logger object for error logging.
 * @returns {Promise<React.Component>} resolves to the loaded component or an error component.
 */
const lazyRetry = function lazyRetry(componentImport, name, logger) {
  const storageKey = `retry-${name}-refreshed`;
  const urlParamKey = `retry-${name}-refreshed`;

  // Determine storage availability
  const sessionAvailable = isSessionStorageAvailable();

  return new Promise((resolve) => {
    let hasRefreshed = false;

    if (sessionAvailable) {
      // Attempt to read from sessionStorage
      hasRefreshed = getSessionItem(storageKey, false);
    } else {
      // Fallback to URL parameters
      hasRefreshed = getQueryParam(urlParamKey) === 'true';
    }

    componentImport()
      .then((component) => {
        // On successful load, reset the retry flag
        if (sessionAvailable) {
          setSessionItem(storageKey, false);
        } else {
          removeQueryParam(urlParamKey);
        }
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          if (sessionAvailable) {
            // Set the retry flag in sessionStorage
            setSessionItem(storageKey, true);
          } else {
            // Set the retry flag via URL parameters
            setQueryParam(urlParamKey, 'true');
          }
          // Reload the page to attempt a retry
          window.location.reload();
          return;
        }

        // If already retried, log the error as info and resolve with the error component
        logger.info(`Error loading lazy component ${name}`, error);
        resolve({ default: FhxError });
      });
  });
};

export default lazyRetry;
